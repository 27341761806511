import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { SideMenuHideShowContext, UserDatailContext } from "../Context/userContext";
import { AuthContext } from "../Context/context";
import userimg from '../assets/images/user_icon.jpg'
import BrandLogo from "../assets/images/main_logo.svg";
export default function New_header(props) {
    const { signInFun, signOutFun } = React.useContext(AuthContext);
    const [sideMeneShowHide,] = useContext(SideMenuHideShowContext);
    const [userDetails,] = useContext(UserDatailContext);
    const [dropdownShow, setDropDownShow] = useState(null)
    // const [initialRender, setInitialRender] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const [activeTab, setActiveTab] = useState(splitLocation[1])
    const ids = ["anjali@4basecare.com", "kshitij@user", "oncoviz-analyst@mailinator.com"];
    const mails = ["anjali@4basecare.com", "kshitij@user", "oncoviz-analyst@mailinator.com", "amandeep@mpadvisor.com"];
    const mails1 = ["user1@elucidata.com", "user1@indegene.com", "user1@iqvia.com", "user2@elucidata.com", "shankara@4bcresearch.com"]
    const mails2 = ["user1@elucidata.com", "user1@indegene.com", "user1@iqvia.com", "user2@elucidata.com", "amandeep@mpadvisor.com", "shankara@4bcresearch.com"]
    // const ids = ["anjali@4basecare.com","kshitij@user","oncoviz-analyst@mailinator.com"];
    const value = ids?.includes(userDetails?.email)
    const value1 = mails?.includes(userDetails?.email)
    const value2 = mails1?.includes(userDetails?.email)
    const value3 = !(mails2?.includes(userDetails?.email))

    function logout(params) {
        navigate('/login')
        signOutFun()
    }

    useEffect(() => {
        if (userDetails.email === 'roche@user') {
            navigate('/PDLAnyalsis');
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    useEffect(() => {
        if (userDetails.email === 'roche@user') {
            if (location.pathname !== '/PDLAnyalsis' && location.pathname !== '/login') {
                logout()

            }
        }
        else if (value2) {
            if (location.pathname !== '/Dashboard' && location.pathname !== '/login') {
                logout()
            }
        }
        else if (!value && (value2)) {
            if (location.pathname !== '/' && location.pathname !== '/login' && location.pathname !== '/Dashboard') {
                logout()
            }
        }
        else if (userDetails?.email === "amandeep@mpadvisor.com") {
            if (location.pathname !== '/' && location.pathname !== '/login') {
                logout()

            }
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [location.pathname, userDetails.email]);

    useEffect(() => {
        window.scrollTo({ top: 0 });

        if (location.pathname === "/Home" || activeTab === 'Home') {
            setActiveTab("/Home")
            setDropDownShow(null)
        }
        else if (location.pathname === "/" || activeTab === '') {
            setActiveTab("/")
            setDropDownShow(null)
        }
        else if (activeTab === 'GeneAnalysis') {
            setActiveTab('GeneAnalysis')

        }
        else if (location.pathname === "/PDLAnyalsis" || activeTab === 'PDLAnyalsis') {
            setActiveTab("PDLAnyalsis")
        }
        // else if (activeTab === '/ALKAnyalsis' ||activeTab === 'ALKAnyalsis') {
        //     setActiveTab('ALKAnyalsis')
        // }
        // else if (activeTab === '/NTRKAnyalsis' ||activeTab === 'NTRKAnyalsis') {
        //     setActiveTab('NTRKAnyalsis')
        // }
        // else if (activeTab === '/ROSAnyalsis' ||activeTab === 'ROSAnyalsis') {
        //     setActiveTab('ROSAnyalsis')
        // }
        // else if (activeTab === '/RETAnyalsis' ||activeTab === 'RETAnyalsis') {
        //     setActiveTab('RETAnyalsis')
        // }
        // else if (activeTab === '/EGFRAnyalsis' ||activeTab === 'EGFRAnyalsis') {
        //     setActiveTab('EGFRAnyalsis')
        // }
        // else if (activeTab === '/ERBB2Anyalsis' ||activeTab === 'ERBB2Anyalsis') {
        //     setActiveTab('ERBB2Anyalsis')
        // }

    }, [location.pathname, activeTab]);


    useEffect(() => {
        if (sideMeneShowHide) {
            setDropDownShow(null)
        } else {
            // setDropDownShow(null) 
        }
    }, [sideMeneShowHide])

    function homePage() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setActiveTab('/')
        setDropDownShow(null)
        navigate('/')
    }

    function dashboardPage() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setActiveTab('Dashboard')
        setDropDownShow(null)
        navigate('/Dashboard')
    }

    function PDLAnyalsisPage() {
        setActiveTab('PDLAnyalsis')
        navigate('/PDLAnyalsis')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }

    function ALKPage() {
        navigate('/ALKAnyalsis')
        setActiveTab('ALKAnyalsis')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    function NTRKPage() {
        navigate('/NTRKAnyalsis')
        setActiveTab('NTRKAnyalsis')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    function ROSKPage() {
        navigate('/ROSAnyalsis')
        setActiveTab('ROSAnyalsis')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    function RETKPage() {
        navigate('/RETAnyalsis')
        setActiveTab('RETAnyalsis')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    function EGFRKPage() {
        navigate('/EGFRAnyalsis')
        setActiveTab('EGFRAnyalsis')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    function ERBB2Page() {
        navigate('/ERBB2Anyalsis')
        setActiveTab('ERBB2Anyalsis')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    function GeneAnalytics(params) {
        setActiveTab('GeneAnalysis')
        setDropDownShow(!dropdownShow)
        // navigate('/ALKAnyalsis')
        //  navigate('/PDLAnyalsis')
        // setActiveTab('PDLAnalysis')
    }



    return (
        <header>
            <div className=" header-row ">
                <div className="header_left">
                    <div className="logo_section">
                        <div className="d-flex">
                            <div>
                                <img src="../../../assets/images/logo_icon.png" alt="" className="h-58" />
                            </div>
                            <div className="logo_title pl-2 mt-lg-1 mt-sm-0">
                                {/* <h3 className="mb-0 font-weight-bold" >OncoBiz</h3> */}
                                <img src={BrandLogo} className="w-60 onco_logo" onClick={() => navigate("/")} />
                                {/* <img src={OncoBizLogo} /> */}
                                <span className="pl-1 text-cyan_light"> </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header_right">
                    <div className="menu_list d-flex">
                        <ul className="list-unstyled d-flex mb-0 nav_list align-items-center">
                            {value1 && <li onClick={() => homePage()}><a routerlinkactive="active" className={activeTab === '' || location.pathname === '/' ? "is-active" : ''}>Home</a></li>}
                            {/* {userDetails.email !== "roche@user" && userDetails.email !== "amandeep@mpadvisor.com"*/}{(value || value2) && <li onClick={() => dashboardPage()}><a routerlinkactive="active" className={activeTab === 'Dashboard' || location.pathname === '/Dashboard' ? "is-active" : ''}>Dashboard</a></li>}
                            {/*userDetails.email !== "amandeep@mpadvisor.com"*/}{value3 && <li onClick={() => PDLAnyalsisPage()}><a routerlinkactive="active" className={activeTab === 'PDLAnyalsis' || location.pathname === '/PDLAnyalsis' ? "is-active" : ''} >PD-L1</a></li>}
                            <li className="gene_list">
                                <a routerlink="" routerlinkactive="active"></a>
                                <div className="dropdown" >
                                    {value && <a className={`dropbtn ${activeTab === 'ALKAnyalsis' || activeTab === 'NTRKAnyalsis' || activeTab === 'ROSAnyalsis' || activeTab === 'EGFRAnyalsis' || activeTab === 'RETAnyalsis' || activeTab === 'GeneAnalysis' || activeTab === 'ERBB2Anyalsis' ? "is-active" : ''}`} onClick={() => GeneAnalytics()}> Gene Analysis</a >}
                                    <div className="dropdown-content bg-white">
                                        <a className={`dropdown-item ${activeTab === 'ALKAnyalsis' || location.pathname === '/ALKAnyalsis' ? 'is-active' : ''}`} onClick={() => ALKPage()} > ALK Analysis</a>
                                        <a className={`dropdown-item ${activeTab === 'NTRKAnyalsis' || location.pathname === '/NTRKAnyalsis' ? "is-active" : ''}`} onClick={() => NTRKPage()}> NTRK Analysis</a>
                                        <a className={`dropdown-item ${activeTab === 'ROSAnyalsis' || location.pathname === '/ROSAnyalsis' ? "is-active" : ''}`} onClick={() => ROSKPage()}> ROS Analysis</a>
                                        <a className={`dropdown-item ${activeTab === 'RETAnyalsis' || location.pathname === '/RETAnyalsis' ? "is-active" : ''}`} onClick={() => RETKPage()}> RET Analysis</a>
                                        <a className={`dropdown-item ${activeTab === 'EGFRAnyalsis' || location.pathname === '/EGFRAnyalsis' ? "is-active" : ''}`} onClick={() => EGFRKPage()}> EGFR Analysis</a>
                                        <a className={`dropdown-item ${activeTab === 'ERBB2Anyalsis' || location.pathname === '/ERBB2Anyalsis' ? "is-active" : ''}`} onClick={() => ERBB2Page()}> ERBB2 Analysis</a>
                                    </div>
                                </div>
                            </li>
                            <li className="gene_list">
                                <button routerlink="" routerlinkactive="active"></button>
                                <div className="dropdown" >
                                    <img src={userimg} alt="" />
                                    <div className="dropdown-content bg-white">
                                        <button className="dropdown-item" onClick={() => logout()}> LogOut</button>
                                    </div>
                                </div>
                            </li>



                        </ul>

                    </div>
                </div>
            </div >
        </header >
    )
}